<template>
  <div>
    <PageTitle page-title="Pre-Owned Vehicles" />

    <!--Listing-->
    <section class="listing-page">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-md-push-3">
            <b-row class="result-sorting-wrapper">
              <b-col cols="3" class="sorting-count">
                <p>{{ itemsPageRange }} <span>of {{ totalVehicles }} Listings</span></p>
              </b-col>
              <b-col class="result-sorting-by">
                <p>Show:</p>
                <form action="#" method="post">
                  <div class="form-group select sorting-select">
                    <select v-model="selectItemsPerPage" class="form-control ">
                      <option :value="5">5 per page</option>
                      <option :value="10">10 per page</option>
                      <option :value="25">25 per page</option>
                      <option :value="50">50 per page</option>
                    </select>
                  </div>
                </form>
              </b-col>
              <b-col class="result-sorting-by">
                <p>Sort by:</p>
                <form action="#" method="post">
                  <div class="form-group select sorting-select">
                    <select v-model="selectItemsSortBy" class="form-control ">
                      <option value="newestFirst">Date added (newest first)</option>
                      <option value="oldestFirst">Date added (oldest first)</option>
                      <option value="priceHigh">Price (high to low)</option>
                      <option value="priceLow">Price (low to high)</option>
                    </select>
                  </div>
                </form>
              </b-col>
            </b-row>

            <div v-for="(v, index) in currentPageVehicles" :key="index" class="product-listing-m gray-bg">
              <div class="product-listing-img">
                <div v-if="v.advert.tag" class="ribbon-holder">
                  <div class="ribbon ribbon-holder">{{ v.advert.tag }}</div>
                    <img @click="navigateCarDetail(v.id)" :src="getThumbImage(v)" class="img-fluid" style="cursor: pointer;" :alt="v.advert.title" />
                </div>
                <img v-else @click="navigateCarDetail(v.id)" :src="getThumbImage(v)" class="img-fluid" style="cursor: pointer;" :alt="v.advert.title" />
              </div>
              <div class="product-listing-content">
                <b-row>
                  <b-col cols="12">
                    <p class="featured-price">£{{ thousandsSeparators(v.advert.price) }}</p>
                  </b-col>
                </b-row>
                <h5 @click="navigateCarDetail(v.id)" style="cursor: pointer;">{{ v.advert.title }}</h5>
                <p class="list-price">{{ v.advert.subtitle }}</p>
                <ul>
                  <li><i class="fa fa-calendar" aria-hidden="true"></i>{{ formatYear(v.vehicle.registrationDate) }}</li>
                  <li><i class="fa fa-road" aria-hidden="true"></i>{{ thousandsSeparators(v.vehicle.mileage) }} miles</li>
                  <li><i class="fa fa-paint-brush" aria-hidden="true"></i>{{ v.vehicle.colour }}</li>
                  <li><i class="fa fa-tachometer" aria-hidden="true"></i>{{ formatEngine(v.vehicle.engine) }} L</li>
                  <li><i class="fa fa-car" aria-hidden="true"></i>{{ v.vehicle.fuelType }}</li>
                  <li><i class="fa fa-cogs" aria-hidden="true"></i>{{ v.vehicle.transmission }}</li>
                </ul>
                <a @click="navigateCarDetail(v.id)" class="btn" style="color: #fff;">View Details <span class="angle_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
              </div>
            </div>

            <div class="pagination">
              <ul>
                <li
                  v-for="page in numPages"
                  :key="page"
                  :class="page === itemsCurrentPage ? 'current' : ''"
                  :style="page !== itemsCurrentPage ? 'cursor: pointer;' : ''"
                  @click="setItemsCurrentPage(page); scrollToTop()">
                  <template v-if="page === itemsCurrentPage">{{ page }}</template>
                  <span v-else style="color: #000;">{{ page }}</span>
                </li>
              </ul>
            </div>
          </div>
          
          <!--Side-Bar-->
          <aside class="col-md-3 col-md-pull-9">
            <div class="sidebar_widget">
              <div class="widget_heading">
                <h5><i class="fa fa-filter" aria-hidden="true"></i> Find Your Ideal Car </h5>
              </div>
              <div class="sidebar_filter">
                <div class="form-group select">
                  <select v-model="selectItemsFilterMake" class="form-control">
                    <option :value="null">Any Make</option>
                    <option v-for="(make, index) in getItemsFilterMakes" :key="index" :value="make">{{ make }}</option>
                  </select>
                </div>
                <div class="form-group select">
                  <select v-model="selectItemsFilterModel" class="form-control">
                    <option :value="null">Any Model</option>
                    <option v-for="(model, index) in getItemsFilterModels" :key="index" :value="model">{{ model }}</option>
                  </select>
                </div>
                <div class="form-group select">
                  <select v-model="selectItemsFilterYear" class="form-control">
                    <option :value="null">Any Year</option>
                    <option v-for="(model, index) in getItemsFilterYears" :key="index" :value="model">{{ model }}</option>
                  </select>
                </div>
                <div class="form-group select">
                  <select v-model="selectItemsFilterFuel" class="form-control">
                    <option :value="null">Any Fuel Type</option>
                    <option v-for="(model, index) in getItemsFilterFuels" :key="index" :value="model">{{ model }}</option>
                  </select>
                </div>
                <div class="form-group select">
                  <select v-model="selectItemsFilterMileage" class="form-control">
                    <option :value="null">Any Mileage</option>
                    <option :value="5000">Up to 5k miles</option>
                    <option :value="10000">Up to 10k miles</option>
                    <option :value="20000">Up to 20k miles</option>
                    <option :value="30000">Up to 30k miles</option>
                    <option :value="40000">Up to 40k miles</option>
                    <option :value="50000">Up to 50k miles</option>
                    <option :value="75000">Up to 75k miles</option>
                    <option :value="100000">Up to 100k miles</option>
                    <option :value="125000">Up to 125k miles</option>
                    <option :value="150000">Up to 150k miles</option>
                    <option :value="999999">Over 150k miles</option>
                  </select>
                </div>
                <div class="form-group select">
                  <select v-model="selectItemsFilterPrice" class="form-control">
                    <option :value="null">Any Price</option>
                    <option :value="1000">Up to £1000</option>
                    <option :value="2000">Up to £2500</option>
                    <option :value="5000">Up to £5000</option>
                    <option :value="7500">Up to £7500</option>
                    <option :value="10000">Up to £10000</option>
                    <option :value="15000">Up to £15000</option>
                    <option :value="999999">Over £15000</option>
                  </select>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-block" @click="resetFilters"><i class="fa fa-repeat" aria-hidden="true"></i> Reset</button>
                </div>
              </div>
            </div>
            <div class="sidebar_widget sell_car_quote">
              <div class="white-text div_zindex text-center">
                <h3>Sell Your Car</h3>
                <p>Request a quote and sell your car now!</p>
                <router-link to="/sellyourcar" class="btn" style="color: #fff;">Request a Quote <span class="angle_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></span></router-link>
              </div>
              <div class="dark-overlay"></div>
            </div>
            <div class="sidebar_widget">
              <div class="widget_heading">
                <h5><i class="fa fa-car" aria-hidden="true"></i> Recently Listed Cars</h5>
              </div>
              <div class="recent_addedcars">
                <ul>
                  <li
                    v-for="(v, index) in recentlyAddedVehicles"
                    :key="index"
                    class="gray-bg"
                    style="cursor: pointer;"
                    @click="navigateCarDetail(v.id)">
                    <div class="recent_post_img">
                      <img :src="getThumbImage(v)" :alt="v.advert.title">
                    </div>
                    <div class="recent_post_title">
                      <b>{{ v.vehicle.make }} {{ v.vehicle.model }}</b>
                      <p class="widget_price">£{{ thousandsSeparators(v.advert.price) }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
          <!--/Side-Bar--> 
        </div>
      </div>
    </section>
    <!-- /Listing--> 
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Inventory',
  components: {
    PageTitle: () => import('../components/PageTitle')
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState('stock', [
      'itemsPerPage',
      'itemsSortBy',
      'itemsCurrentPage',
      'itemsFilterMake',
      'itemsFilterModel',
      'itemsFilterYear',
      'itemsFilterFuel',
      'itemsFilterMileage',
      'itemsFilterPrice'
    ]),
    ...mapGetters('stock', ['getActiveVehicles']),

    totalVehicles() {
      return this.vehiclesFilteredSorted.length
    },
    recentlyAddedVehicles() {
      const vehicles = this.getActiveVehicles.sort((a, b) => {
        return (!a.created) ? 0 : moment(a.created).isBefore(moment(b.created)) ? 1 : -1
      })
      return vehicles.slice(0, 4)
    },
    vehiclesFilteredSorted() {
      let vehicles = this.getActiveVehicles

      // Filter
      if (this.selectItemsFilterMake) {
        vehicles = vehicles.filter(v => v.vehicle.make === this.selectItemsFilterMake)
      }
      if (this.selectItemsFilterModel) {
        vehicles = vehicles.filter(v => v.vehicle.model === this.selectItemsFilterModel)
      }
      if (this.selectItemsFilterYear) {
        vehicles = vehicles.filter(v => moment(v.vehicle.registrationDate).format('YYYY') === this.selectItemsFilterYear)
      }
      if (this.selectItemsFilterFuel) {
        vehicles = vehicles.filter(v => v.vehicle.fuelType === this.selectItemsFilterFuel)
      }
      if (this.selectItemsFilterMileage) {
        vehicles = vehicles.filter(v => v.vehicle.mileage <= this.selectItemsFilterMileage)
      }
      if (this.selectItemsFilterPrice) {
        vehicles = vehicles.filter(v => v.advert.price <= this.selectItemsFilterPrice)
      }

      // Return sorted
      switch (this.itemsSortBy) {
        case 'newestFirst':
          return vehicles.sort((a, b) => {
            return (!a.created) ? 0 : moment(a.created).isBefore(moment(b.created)) ? 1 : -1
          })
        case 'oldestFirst':
          return vehicles.sort((a, b) => {
            return (!a.created) ? 0 : moment(a.created).isAfter(moment(b.created)) ? 1 : -1
          })
        case 'priceHigh':
          return vehicles.sort((a, b) => {
            return (!a.advert.price) ? 0 : parseInt(a.advert.price) < parseInt(b.advert.price) ? 1 : -1
          })
        case 'priceLow':
          return vehicles.sort((a, b) => {
            return (!a.advert.price) ? 0 : parseInt(a.advert.price) > parseInt(b.advert.price) ? 1 : -1
          })
        default:
          return vehicles
      }
    },
    currentPageVehicles() {
      return this.vehiclesFilteredSorted.slice((this.itemsCurrentPage - 1) * this.itemsPerPage, this.itemsCurrentPage * this.itemsPerPage)
    },
    itemsPageRange() {
      const min = Math.floor((this.itemsCurrentPage*this.itemsPerPage) - this.itemsPerPage + 1)
      const max = min + parseInt(this.itemsPerPage) - 1
      const maxCeil = max >= this.totalVehicles ? this.totalVehicles : max
      return min + ' - ' + maxCeil
    },
    numPages() {
      const numPages = Math.ceil(this.totalVehicles / this.itemsPerPage)
      let pages = []
      let i
      for (i = 1; i <= numPages; i++) {
        pages.push(i)
      }
      return pages
    },

    selectItemsPerPage: {
      get() {
        return this.itemsPerPage
      },
      set(val) {
        this.setItemsPerPage(val)
      }
    },
    selectItemsSortBy: {
      get() {
        return this.itemsSortBy
      },
      set(val) {
        this.setItemsSortBy(val)
      }
    },
    selectItemsFilterMake: {
      get() {
        return this.itemsFilterMake
      },
      set(val) {
        this.setItemsFilterMake(val)
      }
    },
    selectItemsFilterModel: {
      get() {
        return this.itemsFilterModel
      },
      set(val) {
        this.setItemsFilterModel(val)
      }
    },
    selectItemsFilterYear: {
      get() {
        return this.itemsFilterYear
      },
      set(val) {
        this.setItemsFilterYear(val)
      }
    },
    selectItemsFilterFuel: {
      get() {
        return this.itemsFilterFuel
      },
      set(val) {
        this.setItemsFilterFuel(val)
      }
    },
    selectItemsFilterMileage: {
      get() {
        return this.itemsFilterMileage
      },
      set(val) {
        this.setItemsFilterMileage(val)
      }
    },
    selectItemsFilterPrice: {
      get() {
        return this.itemsFilterPrice
      },
      set(val) {
        this.setItemsFilterPrice(val)
      }
    },

    getItemsFilterMakes() {
      return Array.from(new Set(this.vehiclesFilteredSorted.map((item) => item.vehicle.make))).sort()
    },
    getItemsFilterModels() {
      return Array.from(new Set(this.vehiclesFilteredSorted.map((item) => item.vehicle.model))).sort()
    },
    getItemsFilterYears() {
      return Array.from(new Set(this.vehiclesFilteredSorted.map((item) => moment(item.vehicle.registrationDate).format('YYYY')))).sort()
    },
    getItemsFilterFuels() {
      return Array.from(new Set(this.vehiclesFilteredSorted.map((item) => item.vehicle.fuelType))).sort()
    }
  },
  watch: {
    itemsPerPage() {
      this.setItemsCurrentPage(1)
      this.scrollToTop()
    },
    vehiclesFilteredSorted() {
      this.setItemsCurrentPage(1)
      this.scrollToTop()
    },
  },
  mounted() {
    this.dbGetAllVehicles()
  },
  beforeDestroy() {
    this.dbUnsubscribeVehicles()
  },
  methods: {
    ...mapActions('stock', [
      'dbGetAllVehicles',
      'dbUnsubscribeVehicles',
      'setItemsSortBy',
      'setItemsPerPage',
      'setItemsCurrentPage',
      'setItemsFilterMake',
      'setItemsFilterModel',
      'setItemsFilterYear',
      'setItemsFilterFuel',
      'setItemsFilterMileage',
      'setItemsFilterPrice'
    ]),

    checkNewSymbol(dt) {
      // If last 7 days, return true
      return moment(dt).diff(moment(), 'days') * - 1 < 7
    },
    formatYear(dt) {
      return moment(dt).format('YYYY')
    },
    formatEngine(size) {
      return (Math.round(size)/1000).toFixed(1)
    },
    getThumbImage(vehicle) {
      return vehicle.images?.length ? vehicle.images[0].url : 'https://firebasestorage.googleapis.com/v0/b/midlandautosales-c2dcf.appspot.com/o/ComingSoon.jpg?alt=media'
    },
    resetFilters() {
      this.selectItemsFilterMake = null
      this.selectItemsFilterModel = null
      this.selectItemsFilterYear = null
      this.selectItemsFilterFuel = null
      this.selectItemsFilterMileage = null
      this.selectItemsFilterPrice = null
    },
    navigateCarDetail(id) {
      this.$router.push({
        name: 'Car Detail',
        params: { id: id }
      })
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>
.page-header {
  background-image: url('../../public/assets/images/midland-auto-sales-inventory-banner.jpg');
}
.sell_car_quote {
  background-image: url('../../public/assets/images/midland-auto-sales-inventory-banner-sell300x300.jpg')  !important;
}
.list-price{
  color: black;
  font-style: normal;
  font-weight: 500;
}
.ribbon-holder {
  overflow: hidden;
  position: relative
}
.ribbon {
  position: absolute;
  background: #DE3732;
  color: #FFFFFF;
  transform: rotate(-45deg);
  text-align: center;
  top: 32px;
  left: -32px;
  width: 145px;
}
</style>
